<template>
  <layout-legacy-component>
    <breadcrumb-component/>
    <div>
      <step1-component
        v-if="step === 1"
        :objects="objects"
        :file-allow-extensions="fileAllowExtensions"
        :file-allow-size="filesAllowSize"
        :next-step-handle="nextStepHandle"
      />
      <step2-component
        v-else-if="step === 2"
        :eval-form="evalForm"
        :next-step-handle="nextStepHandle"
        :prev-step-handle="prevStepHandle"
        v-model:notaries="notaries"
        :notary-form="notaryForm"
        :clear-notary-handler="clearNotaryHandler"
      />
      <step3-component
        v-else-if="step === 3"
        :client-name="clientName"
        :passport="passport"
        :prev-step-handle="prevStepHandle"
        :submit-handle="createSubmitHandle"
      />
      <modal-component v-model:show="notifyWhatsAppModal" title="Уведомления" :width-percent="35" :min-width-px="400">
        <template v-slot:body>
          <div class="send-to-choice">
            <h2>На вашем номере телефона есть WhatsApp. Как вам будет удобнее в дальнейшем получать уведомления по заказу?</h2>
            <div>
              <div class="form-group">
                <input type="radio" name="send-to" value="1" v-model="notifyWhatsApp" />
                <label>по СМС</label>
              </div>
              <div class="form-group">
                <input type="radio" name="send-to" value="2" v-model="notifyWhatsApp" />
                <label>уведомлять на WhatsApp</label>
              </div>
            </div>
            <div class="align-right">
              <button type="button" @click="createSubmitHandle" class="btn btn-primary">Отправить на проверку</button>
            </div>
          </div>
        </template>
      </modal-component>
      <modal-component v-model:show="isConfirmFirstNotify" title="Дополнительная информация" :width-percent="50" :min-width-px="400">
        <template v-slot:body>
          <div class="notify-content">
            <ul>
              <li>1) Если стоимость вашего заказа менее 2000 руб, то <b>оплата будет после готовности</b> и подтверждения заказа. Оплатить вы сможете прямо в личном кабинете картой или по QR-коду. При сумме более 2000 руб. потребуется оплатить 50% стоимости заказа после нашей проверки приложенных документов и подтверждения заказа.</li>
              <li>2) Если по указанным данным свидетельства о смерти к заказу было "привязано" наследственное дело и ведущий его нотариус, то после готовности и оплаты оценки Вы сможете направить ее результаты прямо нотариусу, <b>нажав в заказе кнопку "ОТПРАВИТЬ НОТАРИУСУ"</b>.</li>
            </ul>
            <div class="align-right">
              <button type="button" @click="createSubmitHandle" class="btn btn-primary">Отправить на проверку</button>
            </div>
          </div>
        </template>
      </modal-component>
    </div>
  </layout-legacy-component>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta } from '@unhead/vue';
import { createRequest } from "../../../../api/preOrders";
import { useRouter } from "vue-router";
import {
  BREADCRUMBS_SET_ITEM,
  CART_CLEAR,
  CART_GET_CART_DEFAULT,
  DEFAULT_HIDE_LOADER, DEFAULT_SHOW_LOADER
} from "../../../../store/constants";
import LayoutLegacyComponent from "../../../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import Step1Component from "./step1/Step1Component.vue";
import Step2Component from "./step2/Step2Component.vue";
import Step3Component from "./step3/Step3Component.vue";
import ModalComponent from "../../../../components/ModalComponent.vue";
import { megabytesToBytes } from "../../../../helpers/file";

export default {
    name: "CartNotaryView",
    components: {
      ModalComponent,
      LayoutLegacyComponent,
      BreadcrumbComponent,
      Step1Component,
      Step2Component,
      Step3Component
    },
    setup() {

      const store = useStore();
      const step = ref(1);
      const objects = ref([]);
      const fileAllowExtensions = ['jpg','jpeg','png','heic','pdf','heif'];
      const filesAllowSize = megabytesToBytes(10);
      const cart = store.getters[CART_GET_CART_DEFAULT];
      const clientName = ref({ value:'', error: '' });
      const evalForm = ref({
        name: { value:'', error: '' },
        date: { value:'', error: '' }
      });
      const passport = ref({
        series: { value:'', error: '' },
        date: { value:'', error: '' },
        from: { value:'', error: '' }
      });
      const notaryForm = ref({
        data: null,
        isFound: null,
        hasTry: false,
        number: '',
        log: []
      });
      const notifyWhatsApp = ref(1);
      const notifyWhatsAppModal = ref(false);
      const notaries = ref([]);
      const isConfirmFirstNotify = ref(false);
      const router = useRouter();

      const initObjects = () => {
        objects.value = cart.items.map(item => {
          const object = store.getters.findObjectById(item.id);
          object.files = [];
          object.filesInfo = [];
          object.filesError = '';
          return JSON.parse(JSON.stringify(object));
        });
      };

      const nextStepHandle = (e) => {
        e.preventDefault();
        step.value = step.value+1;
      };

      const prevStepHandle = (e) => {
        e.preventDefault();
        step.value = step.value-1;
      };

      const clearNotaryHandler = (e) => {
        e.preventDefault();
        notaryForm.value.data = null;
        notaryForm.value.isFound = null;
        notaryForm.value.number = '';
        notaryForm.value.log = [];
        notaries.value = [];
      };

      const getFormData = () => {

        const formData = new FormData();

        formData.append('client_name', clientName.value.value);
        formData.append('report_type', cart.type);
        formData.append('notify_whatsapp', notifyWhatsApp.value);

        let passportDate = '';
        if(passport.value.date.value) {
          passportDate = (new Date(passport.value.date.value)).getTime()/1000;
        }

        formData.append('passport[series]', passport.value.series.value);
        formData.append('passport[date]', passportDate);
        formData.append('passport[from]', passport.value.from.value);

        let evalDate = '';
        if(evalForm.value.date.value) {
          evalDate = (new Date(evalForm.value.date.value)).getTime()/1000;
        }

        formData.append('eval[date]', evalDate);
        formData.append('eval[name]', evalForm.value.name.value);

        formData.append('notary[is_found]', Number(notaryForm.value.isFound));
        formData.append('notary[data]', JSON.stringify(notaryForm.value.data));
        formData.append('notary[number]', notaryForm.value.number);

        if (notaryForm.value.log.length) {
          notaryForm.value.log.map(item => formData.append('notary[log][]', item));
        } else {
          formData.append('notary[log][]', []);
        }

        for (let objectIndex=0; objectIndex < objects.value.length; objectIndex++) {

          const object = objects.value[objectIndex];

          for (let fileIndex=0; fileIndex < object.files.length; fileIndex++) {

            const file = object.files[fileIndex];
            const fileInfo = object.filesInfo[fileIndex];
            console.log(fileInfo);

            formData.append('files[' + objectIndex + '][' + fileIndex + ']', file.data, file.name);
            formData.append('filesInfo[name][' + objectIndex + '][' + fileIndex + ']', fileInfo.name);
            formData.append('filesInfo[size][' + objectIndex + '][' + fileIndex + ']', fileInfo.size);
            formData.append('filesInfo[width][' + objectIndex + '][' + fileIndex + ']', fileInfo.width);
            formData.append('filesInfo[height][' + objectIndex + '][' + fileIndex + ']', fileInfo.height);
          }

          formData.append('objects[objects][' + objectIndex + ']', object.id);
        }

        return formData;
      };

      const createSubmitHandle = (e) => {
        e.preventDefault();
        if(!store.state.auth.user.hasOrder) {
          if (store.state.auth.user.hasWhatsApp && !notifyWhatsAppModal.value) {
            notifyWhatsAppModal.value = true;
            return;
          }
          if(!isConfirmFirstNotify.value) {
            isConfirmFirstNotify.value = true;
            return;
          }
        }
        notifyWhatsAppModal.value = false;
        isConfirmFirstNotify.value = false;
        store.commit(DEFAULT_SHOW_LOADER);
        createRequest(getFormData())
          .then(() => {
            store.dispatch(CART_CLEAR);
            store.commit(DEFAULT_HIDE_LOADER);
            router.push('/orders');
          })
          .catch(err => {
            console.log(err);
            store.commit(DEFAULT_HIDE_LOADER);
          });
      };

      onMounted(() => {
        store.commit(BREADCRUMBS_SET_ITEM, {
            item: { name: 'Корзина товаров', path: '/cart' }
        });
        store.commit(BREADCRUMBS_SET_ITEM, {
            item: { name: 'Оформление', path: '/cart/notary' }
        });
        useSeoMeta({
            title: 'Оформление',
            keywords: '',
            description: '',
        });
        initObjects();
      });

      return {
        step,
        objects,
        fileAllowExtensions,
        filesAllowSize,
        nextStepHandle,
        prevStepHandle,
        evalForm,
        notaries,
        notaryForm,
        clearNotaryHandler,
        clientName,
        passport,
        createSubmitHandle,
        isConfirmFirstNotify,
        notifyWhatsAppModal,
        notifyWhatsApp
      }
    }
}
</script>

<style scoped>

.send-to-choice h2 {
  text-align: center;
}

.send-to-choice .form-group {
  display: inline-block;
  text-align: center;
  width: 50%;
  vertical-align: middle;
}

.send-to-choice .form-group label {
  margin-left: 10px;
}

.notify-content li {
  padding: 10px 0;
  font-size: 17px;
  line-height: 24px;
}

.notify-content button {
  background-color: #3194ca;
  border: none;
}

</style>
